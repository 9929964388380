

import store from '@/store/index'
import {
  ClientUser
} from '@/resources/client_user'
import Swal from 'sweetalert2'

export default {
  async confirmRemove(message, title) {  
    return Swal.fire({
        title: title,
        text: message,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'Cancel',
        confirmButtonClass: "btn btn-danger",
    }).then((result) => {
      return result.isConfirmed
    });
  },
  getTellerUsersAsync(term) {
    return new Promise((resolve, reject) => {
      this.getTellerUsers(term, (result) => {
        resolve(result);
      });
    });
  },
  async getTellerUsers(term, cb) {
    store.commit('setUsers', [])
      const result = await ClientUser.get({
        params: {
          term: term,
          status: 1,
          role_name: 'TELLER'
        }
      });
    
    store.commit('setUsers', result.data.users)
    cb(result.data.users)     
  },
  isManagement() {
    let user = store.state.user
    return user && user.is_management
  },
  toCurrency(amount, decimals = 2) {
    
    if (!amount) return "₱0.00"
    let prefix = "";
    amount = parseFloat(amount)
    if (amount < 0) {
      prefix += "-"
      amount = Math.abs(amount);
    }
    return prefix+'₱'+(amount.toFixed(decimals)).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  },
  isAllowed(name, action) {
    var response = false
   
    if (!name) return true
    if (!action) return true
    var user = store.state.user
    if (user) {
      if (user.permissions) {
        user.permissions.forEach(function(permission) {
          if (permission.action.toLowerCase() === action.toLowerCase() && permission.module.toLowerCase() === name.toLowerCase()) {
            response = true
          }
        })
      }
    }
    return response
  },
  hours() {
    return [
        { value: 0, text: '12:00am - 01:00am' },
        { value: 1, text: '01:00am - 02:00am' },
        { value: 2, text: '02:00am - 03:00am' },
        { value: 3, text: '03:00am - 04:00am' },
        { value: 4, text: '04:00am - 05:00am' },
        { value: 5, text: '05:00am - 06:00am' },
        { value: 6, text: '06:00am - 07:00am' },
        { value: 7, text: '07:00am - 08:00am' },
        { value: 8, text: '08:00am - 09:00am' },
        { value: 9, text: '09:00am - 10:00am' },
        { value: 10, text: '10:00am - 11:00am' },
        { value: 11, text: '11:00am - 12:00pm' },
        { value: 12, text: '12:00pm - 01:00pm' },
        { value: 13, text: '01:00pm - 02:00pm' },
        { value: 14, text: '02:00pm - 03:00pm' },
        { value: 15, text: '03:00pm - 04:00pm' },
        { value: 16, text: '04:00pm - 05:00pm' },
        { value: 17, text: '05:00pm - 06:00pm' },
        { value: 18, text: '06:00pm - 07:00pm' },
        { value: 19, text: '07:00pm - 08:00pm' },
        { value: 20, text: '08:00pm - 09:00pm' },
        { value: 21, text: '09:00pm - 10:00pm' },
        { value: 22, text: '10:00pm - 11:00pm' },
        { value: 23, text: '11:00pm - 12:00am' },
      ]
  },
  concatTime(h_start, h_end) {
      let str = h_start <= 9 ? '0' : ''
      str += (h_start > 12) ? (h_start - 12).toString() : h_start.toString()
      str += ':00 '
      str += (h_start < 12) ? 'am' : 'pm'
      str += ' - '
      str += h_end <= 9 ? '0' : ''
      str += (h_end > 12) ? (h_end - 12).toString() : h_end.toString()
      str += ':00 '
      str += (h_end < 12) ? 'am' : 'pm'
      return str
    },
    getTimeSlotsFromHours(hours) {
      let list = []
      let h_start = null
      let h_end = null
      let newHours = JSON.parse(JSON.stringify(hours));
      newHours.sort(function(a, b) {
        return a - b;
      });

      newHours.forEach((hour, i) => {
        if (h_start == null) {
          h_start = hour
          h_end = hour+1
        } else {
          if (h_end == hour) {
             h_end = hour+1
          } else {
            list.push(this.concatTime(h_start, h_end))
            h_start = hour
            h_end = hour+1
          }
        }

        if ((i + 1) == hours.length) {
          list.push(this.concatTime(h_start, h_end || h_start+1))
        }

      });
      return list
    }
};