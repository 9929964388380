export default [
  {
    name: 'Home',
    icon: 'nc-icon nc-bank',
    path: '/overview',
    module: 'dashboard',
    action: 'view'
  },
  {
    name: 'Clients',
    icon: 'nc-icon nc-bank',
    path: '/clients',
    module: 'clients',
    action: 'view'
  },
  {
    name: 'Search Tickets',
    icon: 'nc-icon nc-bank',
    path: '/client/search-tickets',
    module: 'tickets',
    action: 'update'
  },
  {
    name: 'Passengers',
    icon: 'nc-icon nc-bank',
    path: '/client/passengers',
    module: 'passengers',
    action: 'view'
  },

  {
    name: 'Company Invoices',
    icon: 'nc-icon nc-bank',
    path: '/client/invoices',
    module: 'client_invoices',
    action: 'view'
  },

   {
    name: 'Invoices',
    icon: 'nc-icon nc-bank',
    path: '/invoices',
    module: 'invoices',
    action: 'view'
  },

  {
    name: 'Tickets List',
    icon: 'nc-icon nc-bank',
    path: '/client/all-tickets',
    module: 'client_tickets',
    action: 'view'
  },
  
  {
    name: 'Bookings',
    icon: 'nc-icon nc-bank',
    path: '/client/bookings',
    module: 'bookings',
    action: 'view'
  },
  {
    name: 'Rejected Tickets',
    icon: 'nc-icon nc-bank',
    path: '/client/rejected-tickets',
    module: 'CLIENT_TICKETS',
    action: 'DELETE'
  },
  {
    name: 'Ticketing',
    icon: 'nc-icon nc-bank',
    path: '/client/ticketing',
    module: 'tickets',
    action: 'create'
  },
  {
    name: 'Reports',
    icon: 'nc-icon nc-bank',
    path: '/client/reports',
    collapsed: true,
    module: 'reports',
    action: 'view',
     children: [  
      {
        name: 'User Transactions',
        icon: 'nc-icon nc-bank',
        path: '/client/reports/user-transactions',
        module: 'branch_users',
        action: 'view'
       },
       {
        name: 'Sold Tickets',
        icon: 'nc-icon nc-bank',
        path: '/client/reports/sold-tickets',
        module: 'reports',
        action: 'view'
       },
       {
        name: 'Refunded Tickets',
        icon: 'nc-icon nc-bank',
        path: '/client/reports/refunded-tickets',
        module: 'reports',
        action: 'view'
       },
       {
        name: 'Rescheduled Tickets',
        icon: 'nc-icon nc-bank',
        path: '/client/reports/rescheduled-tickets',
        module: 'reports',
        action: 'view'
       },
       {
        name: 'Upgraded Tickets',
        icon: 'nc-icon nc-bank',
        path: '/client/reports/upgraded-tickets',
        module: 'reports',
        action: 'view'
       },
       {
        name: 'Online Tickets',
        icon: 'nc-icon nc-bank',
        path: '/client/reports/online-sales-report',
        module: 'reports',
        action: 'online_report'
       },
    ]
  },
  {
    name: 'Client Settings',
    icon: 'nc-icon nc-single-copy-04',
    collapsed: true,
    module: 'client_settings',
    action: 'view',
    children: [
      {
    name: 'Branches',
    icon: 'nc-icon nc-bank',
    path: '/client/branches',
    module: 'branches',
    action: 'view'
  },
  {
    name: 'Ports',
    icon: 'nc-icon nc-bank',
    path: '/client/ports',
    module: 'ports',
    action: 'view'
  },
  {
    name: 'Routes',
    icon: 'nc-icon nc-bank',
    path: '/client/routes',
    module: 'routes',
    action: 'view'
  },
  {
    name: 'Accommodations',
    icon: 'nc-icon nc-bank',
    path: '/client/accommodations',
    module: 'accommodations',
    action: 'view'
  },
  {
    name: 'Vessels',
    icon: 'nc-icon nc-bank',
    path: '/client/vessels',
    module: 'accommodations',
    action: 'view'
  },
  {
    name: 'Prices',
    icon: 'nc-icon nc-bank',
    path: '/client/prices',
    module: 'prices',
    action: 'view'
  },
  {
    name: 'Promos',
    icon: 'nc-icon nc-bank',
    path: '/client/promos',
    module: 'promos',
    action: 'view'
      },
  {
    name: 'No Trip Dates',
    icon: 'nc-icon nc-bank',
    path: '/client/no-trip-dates',
    module: 'trips',
    action: 'view'
  },
  {
    name: 'Trips',
    icon: 'nc-icon nc-bank',
    path: '/client/trip-schedules',
    module: 'trips',
    action: 'view'
      },
  {
    name: 'Users',
    icon: 'nc-icon nc-bank',
    path: '/client/users',
    module: 'client_users',
    action: 'view'
  },
    ]
  },
  {
    name: 'Settings',
    icon: 'nc-icon nc-single-copy-04',
    collapsed: true,
    module: 'settings',
    children: [
      {
        name: 'Users',
        path: '/settings/users',
        module: 'users',
        action: 'view'
      },
      {
        name: 'Roles',
        path: '/settings/roles',
        module: 'roles',
        action: 'view'
      }
    ]
  },
]
