<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="login">
                <card type="login">
                  <h3 slot="header" class="header text-center">Login</h3>
                       <el-alert
                       v-if="message"
                        :title="message"
                        type="error">
                      </el-alert>
                   <el-input
                    placeholder="Username or Email"
                    v-model="form.email">
                    <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
                  </el-input>

                  <el-input
                  type="password"
                      placeholder="Password"
                      v-model="form.password">
                      <i slot="prefix" class="el-input__icon el-icon-unlock"></i>
                    </el-input>
                    
                  <br>

                  <!-- <p-checkbox>
                    Subscribe to newsletter
                  </p-checkbox> -->

                  <template slot="footer">
                    <p-button native-type="submit" type="primary" round block class="mb-3" v-loading="loading" :disabled="loading">
                      {{ loading ? 'Signing in' : 'Sign in' }}
                    </p-button>

                    <p-button native-type="button" type="info" round block class="mb-3" :disabled="loading" @click="goToRegister()">
                        New? Create new account now
                      </p-button>

                    <el-link type="warning" class="nav-link danger-link" href="/forgot-password">Forgot Password?</el-link>
                  </template>
                </card>

                  

             
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <background></background>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Checkbox, Button } from 'src/components/UIComponents';
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import { Authenticate } from '@/resources/authenticate'

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button
    },
    created: function() {
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      goToRegister() {
        this.$router.push({ name: 'Register' })
      },
      login() {
        var self = this
        this.loading = true
        Authenticate.login(this.form, function(user, message){
          if (user) {
            let redirect = self.$route.query.redirect
            if (redirect) {
              self.$router.push(redirect)
            } else {
               self.$router.push({ name: 'Home', query: {login: true} })
            }
          } else {
            self.showAlert(message)
          }
          self.loading = false
        })
      },
       showAlert(message) {
      this.message = message
      let vm = this
      setTimeout(function () { vm.message = null }, 5000)
    },
  },
   
    data() {
      return {
        form: {
          email: '',
          password: ''
        },
        loading: false,
        message: null
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
