<template>
<el-tooltip class="item" effect="dark" content="BACK" placement="top-start">
  <el-button type="info" icon="fa fa-arrow-left" circle class="mb-2" @click="back"></el-button>
</el-tooltip>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    }
  }
}
</script>

<style>
</style>
